/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'not-ready-for-battle': {
    width: 25,
    height: 21,
    viewBox: '0 0 25 21',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.265 1.614l-1.072.969L3.765 5.81l1.754-.352 1.143 1.033c-.671.099-1.243.137-1.634.15-.432.007-.783.323-.79.711-.068 1.44.325 2.842.832 4.196.363.973.83 1.887 1.366 2.736.18.285.37.563.565.833l-2.434 2.2-.725-.656a.543.543 0 00-.449-.125.5.5 0 00-.369.26l-1.775 3.558a.425.425 0 00.103.511.534.534 0 00.357.134c.07 0 .141-.014.208-.04l3.937-1.605a.468.468 0 00.29-.333.429.429 0 00-.14-.406l-.723-.653 2.305-2.083a17.676 17.676 0 004.278 3.788l.004.004c.134.08.288.123.449.123a.941.941 0 00.495-.153l.005-.004a17.748 17.748 0 004.232-3.759l2.307 2.085-.722.652a.427.427 0 00-.14.404c.03.149.138.273.29.335l3.937 1.605c.066.026.137.04.207.04h.01c.274-.004.496-.207.496-.457a.414.414 0 00-.056-.21l-1.764-3.536a.499.499 0 00-.37-.26.543.543 0 00-.448.125l-.725.656-2.436-2.2a14.981 14.981 0 001.984-3.716c.45-1.246.848-2.695.78-4.017l-.002-.037c-.01-.385-.358-.699-.785-.706a14.921 14.921 0 01-1.637-.15l1.155-1.044 1.74.363 3.573-3.228-1.071-.968.751-.68a.426.426 0 000-.645.544.544 0 00-.715 0l-.75.679L21.584 0l-3.573 3.228.39 1.586-1.609 1.454c-1.293-.303-2.743-.843-3.94-1.807a.417.417 0 00-.053-.038.49.49 0 00-.063-.042.873.873 0 00-.413-.109h-.015a.844.844 0 00-.526.188c-1.197.964-2.648 1.505-3.94 1.808L6.22 4.803l.402-1.575L3.051 0 1.98.969 1.228.29a.543.543 0 00-.714 0 .425.425 0 000 .646l.75.678zm10.548 14.701c0 .253.226.456.505.456s.505-.203.505-.456V7.753c0-.253-.226-.457-.505-.457s-.505.204-.505.457v8.562z" _fill="#fff"/>'
  }
})
